import React from 'react';
import logo from ".././images/logo.png"
import "./Header.css"

const Header = () => {
    return (
        <div className="mi-section">
            <img src={logo} alt="Logo" className="logo" />
            <h1>CHADVENTURES</h1>
            <div className="botones-container">
                <a href="https://t.me/ChadVentureSol" target="_blank" rel="noopener noreferrer">
                    <button className="btn-23 boton">
                        <span className="text">TELEGRAM</span>
                        <span aria-hidden="" className="marquee">TG</span>
                    </button>
                </a>
                <a href="https://x.com/ChadVentureSol" target="_blank" rel="noopener noreferrer">
                    <button className="btn-23 boton">
                        <span className="text">TWITTER</span>
                        <span aria-hidden="" className="marquee">TWITTER</span>
                    </button>
                </a>
                <a href="https://pump.fun" target="_blank" rel="noopener noreferrer">
                    <button className="btn-23 boton">
                        <span className="text">BUY</span>
                        <span aria-hidden="" className="marquee">BUY</span>
                    </button>
                </a>
            </div>
        </div>
    );
};

export default Header;
